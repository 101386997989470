<template>
  <div id="payment-details">
    <Headline />
    <AccountPayment />
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import AccountPayment from '../../components/account/AccountPayment'

export default {
  components: {
    Headline,
    AccountPayment
  }
}
</script>
